import { NgModule } from '@angular/core';
import { NgxStickyfillDirective } from './ngx-stickyfill.directive';

@NgModule({
  declarations: [NgxStickyfillDirective],
  imports: [
  ],
  exports: [NgxStickyfillDirective]
})
export class NgxStickyfillModule { }
